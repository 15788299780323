import Layout from "../components/layout";
import React from "react";
import SEO from "../components/seo";
import ContainerForPages from "../components/containerForPages";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <ContainerForPages>
          <SEO title="404: Not found" />
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </ContainerForPages>
      </Layout>
    )
  );
};

export default NotFoundPage;
